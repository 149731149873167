function Gitbook() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_578_41)">
        <path
          d="M10.8001 18.2749C11.1751 18.2749 11.5126 18.5749 11.5126 18.9874C11.5126 19.3624 11.2126 19.6999 10.8001 19.6999C10.4251 19.6999 10.0876 19.3999 10.0876 18.9874C10.0876 18.5749 10.4251 18.2749 10.8001 18.2749ZM21.8251 13.9249C21.4501 13.9249 21.1126 13.6249 21.1126 13.2124C21.1126 12.8374 21.4126 12.4999 21.8251 12.4999C22.2001 12.4999 22.5376 12.7999 22.5376 13.2124C22.5376 13.5874 22.2001 13.9249 21.8251 13.9249ZM21.8251 11.0374C20.6251 11.0374 19.6501 12.0124 19.6501 13.2124C19.6501 13.4374 19.6876 13.6624 19.7626 13.8874L12.6001 17.7124C12.1876 17.1124 11.5126 16.7749 10.8001 16.7749C9.97505 16.7749 9.22505 17.2624 8.85005 17.9749L2.40005 14.5999C1.72505 14.2249 1.20005 13.1374 1.27505 12.0874C1.31255 11.5624 1.50005 11.1499 1.76255 10.9999C1.95005 10.8874 2.13755 10.9249 2.40005 11.0374L2.43755 11.0749C4.16255 11.9749 9.75005 14.8999 9.97505 15.0124C10.3501 15.1624 10.5376 15.2374 11.1751 14.9374L22.7251 8.93744C22.9126 8.86244 23.1001 8.71244 23.1001 8.44994C23.1001 8.11244 22.7626 7.96244 22.7626 7.96244C22.0876 7.66244 21.0751 7.17494 20.1001 6.72494C18.0001 5.74994 15.6001 4.62494 14.5501 4.06244C13.6501 3.57494 12.9001 3.98744 12.7876 4.06244L12.5251 4.17494C7.76255 6.57494 1.46255 9.68744 1.08755 9.91244C0.450053 10.2874 0.0375525 11.0749 5.2521e-05 12.0499C-0.0749475 13.5874 0.712552 15.1999 1.83755 15.7624L8.66255 19.2874C8.81255 20.3374 9.75005 21.1624 10.8001 21.1624C12.0001 21.1624 12.9376 20.2249 12.9751 19.0249L20.4751 14.9749C20.8501 15.2749 21.3376 15.4249 21.8251 15.4249C23.0251 15.4249 24.0001 14.4499 24.0001 13.2499C24.0001 12.0124 23.0251 11.0374 21.8251 11.0374Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_578_41">
          <rect
            width="24"
            height="24"
            fill="black"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Gitbook;
