function Github() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2567 2.16669C8.21671 2.16775 6.24365 2.89026 4.69029 4.20502C3.13692 5.51979 2.10455 7.34108 1.77776 9.34323C1.45097 11.3454 1.85107 13.3978 2.90653 15.1336C3.962 16.8693 5.60398 18.1752 7.5389 18.8177C7.96577 18.8969 8.12656 18.6323 8.12656 18.4074C8.12656 18.1824 8.11802 17.5302 8.11517 16.8171C5.72468 17.3335 5.21955 15.8083 5.21955 15.8083C4.82968 14.818 4.2662 14.5577 4.2662 14.5577C3.48645 14.0285 4.32455 14.0384 4.32455 14.0384C5.18825 14.0992 5.64216 14.9198 5.64216 14.9198C6.40769 16.2257 7.65273 15.848 8.14221 15.6273C8.21904 15.0741 8.44245 14.6977 8.68861 14.4841C6.77906 14.269 4.77276 13.5362 4.77276 10.2623C4.76092 9.4132 5.0778 8.59211 5.65781 7.96885C5.56959 7.7538 5.27504 6.8851 5.74176 5.70514C5.74176 5.70514 6.46318 5.47594 8.10522 6.58092C9.51365 6.19795 10.9997 6.19795 12.4081 6.58092C14.0487 5.47594 14.7687 5.70514 14.7687 5.70514C15.2368 6.88227 14.9423 7.75097 14.8541 7.96885C15.4359 8.59221 15.7535 9.41474 15.7405 10.2651C15.7405 13.5461 13.73 14.269 11.8176 14.4798C12.1249 14.7458 12.3996 15.2651 12.3996 16.063C12.3996 17.2062 12.3896 18.1258 12.3896 18.4074C12.3896 18.6352 12.5447 18.9011 12.9801 18.8177C14.9152 18.1751 16.5574 16.869 17.6128 15.133C18.6683 13.3969 19.0682 11.3442 18.741 9.34184C18.4138 7.3395 17.381 5.51819 15.8271 4.20365C14.2732 2.88911 12.2997 2.16707 10.2595 2.16669H10.2567Z"
        fill="black"
      />
      <path
        d="M4.9177 14.4346C4.8992 14.477 4.8309 14.4897 4.7754 14.46C4.71991 14.4303 4.67865 14.3751 4.69857 14.3313C4.71849 14.2874 4.78537 14.2761 4.84086 14.3058C4.89635 14.3355 4.93904 14.3921 4.9177 14.4346Z"
        fill="black"
      />
      <path
        d="M5.26641 14.8208C5.23695 14.8356 5.20323 14.8397 5.17105 14.8325C5.13886 14.8252 5.1102 14.8071 5.08997 14.7812C5.03448 14.7218 5.02309 14.6397 5.06578 14.6029C5.10847 14.5661 5.18531 14.5831 5.2408 14.6425C5.29629 14.7019 5.3091 14.784 5.26641 14.8208Z"
        fill="black"
      />
      <path
        d="M5.60497 15.3117C5.55232 15.3485 5.46267 15.3117 5.41287 15.2382C5.3991 15.225 5.38815 15.2091 5.38067 15.1916C5.37318 15.1741 5.36932 15.1553 5.36932 15.1363C5.36932 15.1173 5.37318 15.0984 5.38067 15.0809C5.38815 15.0634 5.3991 15.0476 5.41287 15.0344C5.46552 14.999 5.55517 15.0344 5.60497 15.1066C5.65477 15.1787 5.65619 15.2749 5.60497 15.3117Z"
        fill="black"
      />
      <path
        d="M6.06456 15.7871C6.01761 15.8395 5.92228 15.8253 5.84402 15.7546C5.76576 15.6838 5.74725 15.5876 5.79421 15.5367C5.84116 15.4857 5.9365 15.4999 6.01761 15.5692C6.09871 15.6385 6.11437 15.7362 6.06456 15.7871Z"
        fill="black"
      />
      <path
        d="M6.70921 16.0644C6.68787 16.1309 6.59111 16.1606 6.49435 16.1323C6.39759 16.104 6.33356 16.0248 6.35206 15.9568C6.37056 15.8889 6.46874 15.8578 6.56692 15.8889C6.6651 15.9201 6.72771 15.995 6.70921 16.0644Z"
        fill="black"
      />
      <path
        d="M7.41211 16.1126C7.41211 16.1819 7.33243 16.2413 7.22998 16.2427C7.12753 16.2441 7.04358 16.1876 7.04358 16.1182C7.04358 16.0489 7.12326 15.9895 7.22571 15.9881C7.32816 15.9866 7.41211 16.0418 7.41211 16.1126Z"
        fill="black"
      />
      <path
        d="M8.06653 16.0036C8.07934 16.0729 8.00819 16.145 7.90574 16.162C7.80329 16.179 7.71365 16.138 7.70084 16.0701C7.68804 16.0021 7.76203 15.9286 7.86163 15.9102C7.96124 15.8918 8.05373 15.9342 8.06653 16.0036Z"
        fill="black"
      />
    </svg>
  );
}

export default Github;
